import { rfiHelperApi } from 'api'

export const updateAgencyCategory = ({
  agencyId,
  name,
  instruction,
  parentCategoryId,
  categoryId,
}: {
  agencyId: string
  name: string
  instruction: string | null
  parentCategoryId: string | null
  categoryId: string
}) => rfiHelperApi.put(`/instructions-categories/${categoryId}`, { agencyId, name, instruction, parentCategoryId })
