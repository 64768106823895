import { micromark } from 'micromark'

import { ConvertedQuestion, ImageAttribute } from '../types'

/**
 * Extract the height and width attributes of the image tags in the HTML string
 * @param htmlString The HTML string to extract the image attributes from
 * @returns The height and width attributes of the images in the HTML string
 * */
export const setImageAttributesFromDomNode = (node: HTMLElement) => {
  const imgs = node?.getElementsByTagName('img') || []
  const imgAttributes: ImageAttribute[] = []
  if (imgs?.length > 0) {
    for (let i = 0; i < imgs.length; i++) {
      const img = imgs[i]
      const imgHeight = img.clientHeight
      const imgWidth = img.clientWidth
      const imgTop = img.offsetTop
      const imgLeft = img.offsetLeft
      imgAttributes.push({ height: imgHeight, width: imgWidth, top: imgTop, left: imgLeft })
    }
  }
  return imgAttributes
}
/**
 * Update the height and width attributes of the image tags in the HTML string
 * @param htmlString The HTML string to update
 * @param imageAttributes The new height and width attributes for the images
 * @returns The updated HTML string
 
 * */
export const updateImageAttributesInString = (htmlString: string, imageAttributes: ImageAttribute[]) => {
  const dimensions = [...imageAttributes]

  return htmlString.replace(/<img([^>]+)>/gi, (match, attrs) => {
    const imgAttrs: { [key: string]: any } = {} // Add index signature to allow indexing with a string

    attrs.replace(/(\w+)="([^"]+)"/g, (_: unknown, name: string | number, value: any) => (imgAttrs[name] = value))
    const { height, width } = dimensions.shift() || {}

    if (height && width) {
      imgAttrs.style = `height:${height}px;width:${width}px`
    }
    return `<img ${Object.entries(imgAttrs)
      .map(([name, value]) => `${name}="${value}"`)
      .join(' ')}>`
  })
}

export const pointsInAnInch = 72
export const inchesInAPoint = 0.0138889
export const pixelsInAnInch = 96
export const inchesInAPixel = 0.0104167
export const pointsInAPixel = 0.75
export const pixelsInAPoint = 1.3333333333333333

/**
 * Convert pixels to inches
 * @param pixels The number of pixels to convert to inches
 * @returns The calculated ratio in inches
 * */
export const pixelsToInches = (pixels: number) => {
  const pixelsInAnInch = 96
  return pixels / pixelsInAnInch
}

/**
 * Convert pixels to points
 * @param pixels The number of pixels to convert to points
 * @returns The calculated ratio in points
 * */
export const pixelsToPoints = (pixels: number) => {
  return pixels * pointsInAPixel
}

/**
 * Convert inches to points
 * @param inches The number of inches to convert to points
 * @returns The calculated ratio in points
 * */
export const inchesToPoints = (inches: number) => {
  return inches * pointsInAnInch
}

/**
 * Convert inches to pixels
 * @param inches The number of inches to convert to pixels
 * @returns The calculated ratio in pixels
 * */
export const inchesToPixels = (inches: number) => {
  return inches * pixelsInAnInch
}

/**
 * Convert points to pixels
 * @param points The number of points to convert to pixels
 * @returns The calculated ratio in pixels
 */
export const pointsToInches = (points: number) => {
  return points * inchesInAPoint
}

/**
 * Get the dimensions of an image from a data URL
 * @param dataUrl The data URL of the image
 * @returns The width and height of the image
 *
 */
export const getDataUrlImageDimensions = async (dataUrl: string) => {
  const pixelToInch = 96
  const img = new Image()
  img.src = dataUrl
  await img.decode()
  const width = img.width / pixelToInch
  const height = img.height / pixelToInch
  return { width, height }
}

/**
 * Generate an html string from the questions
 * Which runs the markdown parser on the questions and answers
 * @param questions The questions to generate the markdown string from
 * @returns The markdown string
 *   */
export const generateQaSource = (questions: ConvertedQuestion[]): string => {
  return questions.reduce((accum, cat) => {
    const questions = cat.questions
    accum +=
      ' ' +
      questions.reduce((qum, q) => {
        const questionText = `#### ${q.questionText}`
        const answer = `${q.latestChildQuestionProposedAnswer ?? (q.proposedAnswer || 'Answer not validated/provided')}`

        qum += micromark(questionText)
        qum += micromark(answer)
        return qum
      }, '')

    return accum
  }, '')
}
