import { WppButton, WppEmptyError, WppTypography } from '@wppopen/components-library-react'

export default function RfiSummaryError({ onRetry }: { onRetry: () => void }) {
  return (
    <div className="flex flex-col gap-6 items-center justify-center w-full h-[calc(100lvh-300px)]">
      <WppEmptyError />
      <div className="flex flex-col gap-2 items-center justify-center">
        <WppTypography type="m-strong">Summary Error</WppTypography>
        <WppTypography type="m-body">
          The summary processor encountered an error. Try refreshing the page.{' '}
        </WppTypography>
      </div>
      <WppButton onClick={onRetry} size="m">
        Try Again
      </WppButton>
    </div>
  )
}
