import { InputChangeEventDetail, RichtextChangeEventDetail, uuidv4 } from '@wppopen/components-library'
import { WppInputCustomEvent, WppRichtextCustomEvent } from '@wppopen/components-library/dist/types/components'
import {
  WppActionButton,
  WppButton,
  WppDivider,
  WppEmptyNothingFound,
  // WppIconEdit,
  WppIconPlus,
  WppIconTrash,
  WppInput,
  WppModal,
  WppNavSidebarItem,
  WppRichtext,
  WppTypography,
} from '@wppopen/components-library-react'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { useCreateAgencyCategory } from 'api/mutations/agencyCategories/useCreateAgencyCategory'
import { useDeleteAgencyCategory } from 'api/mutations/agencyCategories/useDeleteAgencyCategory'
import { useUpdateAgencyCategory } from 'api/mutations/agencyCategories/useUpdateAgencyCategory'
import { useGetAgencyCategories } from 'api/queries/agency-categories/useGetAgencyCategories'
import { queryClient } from 'app/Root'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { useToast } from 'hooks/useToast'
import { Agency } from 'types/agencies/agencies'
import { AgencyCategory, Subcategory } from 'types/agency-categories/agency-categories'

import styles from './AgencyEditKnowledgeBase.module.scss'
import { isNonNullable } from '@/types/utils'

type LocalAgencyCategory = AgencyCategory & { isNew?: boolean }
type LocalSubcategory = Subcategory & { isNew?: boolean }

const DeleteButton = ({ handleOnClick, label }: { handleOnClick: () => void; label?: string }) => {
  const [modalOpen, setModalOpen] = useState(false)
  const handleDeleteConfirm = () => {
    // open modal to confirm delete
    setModalOpen(true)
  }

  return (
    <>
      <WppModal open={modalOpen}>
        <h3 slot="header">Delete {label ?? 'category'}</h3>
        <p slot="body">
          This will permanently delete this {label ?? 'category'}. Are you sure you want to delete this?
        </p>
        <div slot="actions" className="flex flex-row justify-end gap-4">
          <WppButton variant="secondary" onClick={() => setModalOpen(false)}>
            Cancel
          </WppButton>
          <WppButton
            variant="destructive"
            onClick={() => {
              setModalOpen(false)
              handleOnClick()
            }}
          >
            Delete
          </WppButton>
        </div>
      </WppModal>
      <WppActionButton variant="secondary" onClick={handleDeleteConfirm} className="h-9 flex items-center space-x-1.5">
        <WppIconTrash color="#DB0025" />{' '}
        <WppTypography type="s-strong" className="text-[#DB0025]">
          Delete
        </WppTypography>
      </WppActionButton>
    </>
  )
}

export const AgencyEditKnowledgeBase = ({
  agency,
  isEditMode,
  setIsEditMode,
}: {
  agency: Agency
  isEditMode: boolean
  setIsEditMode: (isEditMode: boolean) => void
}) => {
  const { data: categories = [] } = useGetAgencyCategories({ params: agency?.id || '', notifyOnChangeProps: 'all' })
  const [localData, setLocalData] = useState<LocalAgencyCategory[]>([])
  const [categoriesToChange, setCategoriesToChange] = useState<(LocalAgencyCategory | LocalSubcategory)[]>([])
  const [categoriesToAdd, setCategoriesToAdd] = useState<(LocalAgencyCategory | LocalSubcategory)[]>([])
  const [categoriesToDelete, setCategoriesToDelete] = useState<string[]>([])
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false)
  const { mutateAsync: createCategory } = useCreateAgencyCategory()
  const { mutateAsync: deleteCategory } = useDeleteAgencyCategory()
  const { mutateAsync: updateCategory } = useUpdateAgencyCategory()
  const [selectedCategory, setSelectedCategory] = useState<LocalAgencyCategory | LocalSubcategory | null>(null)
  const sideMenuRef = useRef<HTMLDivElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)
  const toast = useToast()
  const [currentBreakPoint, setCurrentBreakPoint] = useState<'sm' | 'md' | 'lg' | 'xl' | '2xl'>('sm')

  const handleClickCategory = (category: LocalAgencyCategory | LocalSubcategory) => {
    setSelectedCategory(category)
  }

  const currentCategory = useMemo(() => {
    if (selectedCategory) {
      if (selectedCategory.parentCategoryId) {
        return localData.find(c => c.id === selectedCategory.parentCategoryId)
      }

      return localData.find(c => c.id === selectedCategory.id)
    }
    return null
  }, [localData, selectedCategory])

  const handleCategoryNameChange =
    (category: LocalAgencyCategory | LocalSubcategory) => async (e: WppInputCustomEvent<InputChangeEventDetail>) => {
      const name = e.detail.value || ''
      const newCategory = { ...category, name }
      setLocalData(
        localData.map(c => {
          if (!newCategory.parentCategoryId) {
            if (c.id !== newCategory.id) {
              return c
            }

            return newCategory as AgencyCategory
          }

          if (c.id !== newCategory.parentCategoryId) {
            return c
          }

          return {
            ...c,
            subcategories: c.subcategories.map(subcategory => {
              if (subcategory.id !== newCategory.id) {
                return subcategory
              }
              return newCategory
            }),
          }
        }),
      )
      if (!newCategory.isNew) {
        setCategoriesToChange([...categoriesToChange.filter(c => c.id !== newCategory.id), newCategory])
      } else {
        setCategoriesToAdd([...categoriesToAdd.filter(c => c.id !== newCategory.id), newCategory])
      }
    }

  const handleSubcategoryInstructionChange =
    (subcategory: LocalSubcategory) => async (e: WppRichtextCustomEvent<RichtextChangeEventDetail>) => {
      const instruction = e.detail.value || ''
      const newSubcategory = { ...subcategory, instruction }
      setLocalData(
        localData.map(c => {
          if (c.id !== newSubcategory.parentCategoryId) {
            return c
          }

          return {
            ...c,
            subcategories: c.subcategories.map(subcategory => {
              if (subcategory.id !== newSubcategory.id) {
                return subcategory
              }
              return newSubcategory
            }),
          }
        }),
      )
      if (!newSubcategory.isNew) {
        setCategoriesToChange([...categoriesToChange.filter(c => c.id !== newSubcategory.id), newSubcategory])
      } else {
        setCategoriesToAdd([...categoriesToAdd.filter(c => c.id !== newSubcategory.id), newSubcategory])
      }
    }

  const handleDelete = async (category: LocalAgencyCategory | LocalSubcategory) => {
    if (!category.parentCategoryId) {
      try {
        await deleteCategory(category.id)
        if (categories[0]?.id === category.id) {
          setSelectedCategory(categories[1] ?? null)
        } else {
          setSelectedCategory(categories[0] ?? null)
        }
        setTimeout(() => {
          sideMenuRef.current?.scrollTo({ top: 0, behavior: 'smooth' })
        }, 250)
      } catch (e) {
        toast.showToast({ message: `Failed to delete category : ${e}`, type: 'error' })
      }
      clearEditMode()
      return
    }

    setLocalData(
      localData
        .map(c => {
          if (c.id !== category.parentCategoryId) {
            return c
          }

          return {
            ...c,
            subcategories: c.subcategories
              .map(subcategory => {
                if (subcategory.id !== category.id) {
                  return subcategory
                }
                return null
              })
              .filter(isNonNullable),
          }
        })
        .filter(isNonNullable),
    )
    if (!categoriesToDelete.includes(category.id) && !category.isNew) {
      setCategoriesToDelete([...categoriesToDelete, category.id])
    }
    if (selectedCategory?.id === category.id) {
      const parentCategory = localData.find(c => c.id === category.parentCategoryId)
      if (parentCategory) {
        setSelectedCategory(parentCategory)

        setTimeout(() => {
          const categoryNavElement = document.getElementById(`${parentCategory?.id}-nav`) as HTMLElement
          if (categoryNavElement) {
            categoryNavElement.scrollIntoView({ behavior: 'smooth' })
          }
        }, 250)
      }
    }
  }

  const handleAddCategory = async () => {
    const tempId = uuidv4()

    const newCategory = {
      id: tempId,
      name: '',
      instruction: null,
      parentCategoryId: null,
      agencyId: agency.id,
      subcategories: [],
      isNew: true,
    }
    setLocalData([...localData, newCategory])
    setCategoriesToAdd([...categoriesToAdd, newCategory])
    setIsEditMode(true)
    setSelectedCategory(newCategory)

    setTimeout(() => {
      sideMenuRef.current?.scrollTo({ top: sideMenuRef.current.scrollHeight, behavior: 'smooth' })
    }, 250)
  }

  const handleAddSubcategory = async (category: LocalAgencyCategory) => {
    const parentCategory = localData.find(c => c.id === category.id)
    if (!parentCategory) {
      return
    }

    const tempId = uuidv4()

    const newCategory = {
      id: tempId,
      name: '',
      instruction: '',
      parentCategoryId: category.id,
      agencyId: agency.id,
      isNew: true,
    }

    setLocalData(
      localData.map(data => {
        if (data.id !== category.id) {
          return data
        }

        return {
          ...data,
          subcategories: [...data.subcategories, newCategory],
        }
      }),
    )
    setCategoriesToAdd([...categoriesToAdd, newCategory])
    setSelectedCategory(newCategory)
  }

  const clearEditMode = () => {
    queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.AGENCY_CATEGORIES, agency.id] })
    setIsEditMode(false)
    setCategoriesToAdd([])
    setCategoriesToChange([])
    setCategoriesToDelete([])
  }

  const onCancel = () => {
    setLocalData(categories)
    if (selectedCategory?.isNew) {
      setSelectedCategory(null)
    }
    clearEditMode()
  }

  const onSave = async () => {
    if (categoriesToAdd.length) {
      const parentCategoriesToAdd = categoriesToAdd.filter(c => !c.parentCategoryId)
      const localIdsMap: Record<string, string> = {}
      let createdParent: LocalAgencyCategory | null = null
      for (const category of parentCategoriesToAdd) {
        try {
          const createdCategory = await createCategory({
            agencyId: agency.id,
            name: category.name,
            instruction: category.instruction,
            parentCategoryId: null,
          })
          localIdsMap[category.id] = createdCategory.data.id
          createdParent = createdCategory.data
        } catch (e) {
          toast.showToast({
            message: `Failed to create category : ${e}`,
            type: 'error',
          })
        }
      }
      const subCategoriesToAdd = categoriesToAdd.filter(c => c.parentCategoryId)
      for (const category of subCategoriesToAdd) {
        try {
          await createCategory({
            agencyId: agency.id,
            name: category.name,
            instruction: category.instruction,
            parentCategoryId: localIdsMap[category.parentCategoryId!] ?? category.parentCategoryId!,
          })
        } catch (e) {
          toast.showToast({
            message: `Failed to create subcategory : ${e}`,
            type: 'error',
          })
        }
      }
      if (createdParent) {
        setSelectedCategory(createdParent)
      }
    }
    if (categoriesToChange.length) {
      for (const category of categoriesToChange) {
        try {
          await updateCategory({
            agencyId: agency.id,
            name: category.name,
            instruction: category.instruction,
            parentCategoryId: category.parentCategoryId,
            categoryId: category.id,
          })
        } catch (e) {
          toast.showToast({
            message: `Failed to update ${category.parentCategoryId ? 'subcategory' : 'category'} : ${e}`,
            type: 'error',
          })
        }
      }
    }
    if (categoriesToDelete.length) {
      for (const categoryId of categoriesToDelete) {
        try {
          await deleteCategory(categoryId)
        } catch (e) {
          toast.showToast({ message: `Failed to delete category : ${e}`, type: 'error' })
        }
      }
    }
    clearEditMode()
  }

  const handleHeights = useCallback(() => {
    if (sideMenuRef?.current !== null) {
      sideMenuRef.current.style.height = `${window.innerHeight - 480}px`
    }
    if (containerRef?.current !== null) {
      containerRef.current.style.height = `${window.innerHeight - 420}px`
    }

    switch (true) {
      case window.innerWidth < 768:
        setCurrentBreakPoint('sm')
        break
      case window.innerWidth >= 768 && window.innerWidth < 1024:
        setCurrentBreakPoint('md')
        break
      case window.innerWidth >= 1024 && window.innerWidth < 1280:
        setCurrentBreakPoint('lg')
        break
      case window.innerWidth >= 1280 && window.innerWidth < 1700:
        setCurrentBreakPoint('xl')
        break
      case window.innerWidth >= 1700:
        setCurrentBreakPoint('2xl')
        break
      default:
        setCurrentBreakPoint('sm')
        break
    }
  }, [])

  useEffect(() => {
    handleHeights()
    window.addEventListener('resize', handleHeights)

    return () => {
      window.removeEventListener('resize', handleHeights)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    handleHeights()
  }, [handleHeights])

  useEffect(() => {
    setLocalData(categories)
  }, [categories])

  useEffect(() => {
    if (!selectedCategory) {
      setSelectedCategory(categories[0] ?? null)
    }
  }, [categories, selectedCategory])

  useEffect(() => {
    if (selectedCategory) {
      const categoryElement = document.getElementById(selectedCategory?.id) as HTMLElement
      if (categoryElement) {
        categoryElement.scrollIntoView({ behavior: 'smooth' })
      }
    }
  }, [selectedCategory])

  return (
    <>
      <div className="flex pt-4 items-start" data-testid="AgencyEditKB-container">
        <aside className="basis-1/4 bg-white rounded-lg">
          <div>
            <div className="flex justify-between items-center pb-2 px-4 pt-4">
              <WppTypography type="l-strong">Knowledge Base</WppTypography>
              {/* {categories.length > 0 && (
                <WppActionButton variant="secondary" onClick={() => setIsEditMode(true)}>
                  <WppIconEdit color="#4D5358" />
                </WppActionButton>
              )} */}
            </div>
            <div className="overflow-y-auto py-2 px-4" ref={sideMenuRef}>
              {localData.map((category: AgencyCategory) => {
                return (
                  <div key={`list-${category?.id}`}>
                    <WppNavSidebarItem
                      onClick={e => {
                        e.stopPropagation()
                        handleClickCategory(category)
                      }}
                      key={category.id}
                      active={selectedCategory?.id === category.id}
                      id={`${category.id}-nav`}
                      className={styles.sideMenuItem}
                      label={category.name || 'Untitled Category'}
                      extended={category?.subcategories && category.subcategories.length > 0}
                      maxTitleLengthWithSubItems={
                        currentBreakPoint === '2xl'
                          ? 36
                          : currentBreakPoint === 'xl'
                            ? 29
                            : currentBreakPoint === 'lg'
                              ? 22
                              : 15
                      }
                      maxTitleLengthWithoutSubItems={
                        currentBreakPoint === '2xl'
                          ? 38
                          : currentBreakPoint === 'xl'
                            ? 31
                            : currentBreakPoint === 'lg'
                              ? 24
                              : 17
                      }
                    >
                      {category?.subcategories
                        ? category.subcategories.map(subcategory => {
                            return (
                              <WppNavSidebarItem
                                onClick={e => {
                                  e.stopPropagation()
                                  handleClickCategory(subcategory)
                                }}
                                key={subcategory.id}
                                active={selectedCategory?.id === subcategory.id}
                                id={`${subcategory.id}-nav`}
                                className={styles.sideMenuIndentedItem}
                                label={subcategory.name || 'Untitled Subcategory'}
                                maxTitleLengthWithoutSubItems={
                                  currentBreakPoint === '2xl'
                                    ? 40
                                    : currentBreakPoint === 'xl'
                                      ? 30
                                      : currentBreakPoint === 'lg'
                                        ? 20
                                        : 10
                                }
                              />
                            )
                          })
                        : null}
                    </WppNavSidebarItem>
                  </div>
                )
              })}
            </div>
          </div>
          <div className="border-t border-solid border-[#E7EAEE] p-4">
            <button type="button" className="w-full text-white bg-primary rounded-md p-2" onClick={handleAddCategory}>
              <div className="flex justify-center items-center gap-2 w-full">
                <WppIconPlus color="white" />
                <WppTypography type="s-strong" className="text-white">
                  New Category
                </WppTypography>
              </div>
            </button>
          </div>
        </aside>
        <div className="max-w-[75%] basis-3/4 ml-[15px]">
          <div
            className="content flex flex-col gap-4 py-5 bg-[#FFFFFF] rounded-lg break-words"
            id="instructions-container"
            ref={containerRef}
          >
            {!isEditMode && currentCategory && (
              <div className="overflow-y-auto max-h-full px-5">
                <WppTypography id={currentCategory.id} type="2xl-heading" className="mb-7">
                  {currentCategory.name}
                </WppTypography>

                <div>
                  <div className="flex flex-col space-y-4">
                    {currentCategory.subcategories.map(subcategory => {
                      return (
                        <div className="flex flex-col space-y-0.5" key={subcategory.id}>
                          <WppTypography id={subcategory.id} type="l-strong">
                            {subcategory.name}
                          </WppTypography>
                          {subcategory.instruction && (
                            <div
                              className="text-sm text-[#121619] mt-2"
                              dangerouslySetInnerHTML={{ __html: subcategory.instruction }}
                            />
                          )}
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            )}

            {isEditMode && currentCategory && (
              <div className="pt-6 max-h-full" id={currentCategory.id}>
                <div className="flex items-center justify-between w-full mb-8 space-x-6 px-5">
                  <WppInput
                    name="Category title"
                    placeholder="Type Category Title"
                    value={currentCategory.name}
                    onWppChange={handleCategoryNameChange(currentCategory)}
                    required
                    className="flex-1"
                  />
                  <DeleteButton handleOnClick={() => handleDelete(currentCategory)} />
                </div>

                <div className="overflow-y-auto max-h-[calc(100%-72px)] px-5">
                  <div className="subcategory-list">
                    {currentCategory.subcategories.map(subcategory => {
                      return (
                        <div key={subcategory.id} id={subcategory.id}>
                          <WppDivider />
                          <div className="pt-8 flex items-center justify-between mb-4 space-x-6">
                            <WppInput
                              className="flex-grow flex-1"
                              placeholder="Type Subcategory Title"
                              value={subcategory.name}
                              onWppChange={handleCategoryNameChange(subcategory)}
                            />
                            <DeleteButton label="subcategory" handleOnClick={() => handleDelete(subcategory)} />
                          </div>
                          <div className="flex justify-between mb-8 w-full">
                            <WppRichtext
                              className="w-full"
                              placeholder="Type Subcategory Description"
                              value={subcategory.instruction}
                              onWppChange={handleSubcategoryInstructionChange(subcategory)}
                            />
                          </div>
                        </div>
                      )
                    })}

                    <div className="flex justify-end pb-8">
                      <WppActionButton
                        type="button"
                        className="text-primary"
                        onClick={() => handleAddSubcategory(currentCategory)}
                      >
                        <WppIconPlus color="#0014CC" />
                        <WppTypography type="s-strong" className="text-primary">
                          Add Subcategory
                        </WppTypography>
                      </WppActionButton>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {localData.length === 0 && (
              <div className="flex-1 flex flex-col gap-6 items-center justify-center min-h-96">
                <WppEmptyNothingFound width={120} height={120} />
                <div className="flex flex-col gap-2 items-center">
                  <WppTypography type="m-strong">Knowledge Base is Empty</WppTypography>
                  <WppTypography type="s-body">Add a file to the knowledge base or Manually Add Content</WppTypography>
                </div>
              </div>
            )}
          </div>
          {isEditMode && (
            <div className="p-6 pb-0 flex justify-end gap-x-3">
              <WppButton variant="secondary" onClick={() => setIsConfirmModalOpen(true)}>
                Cancel
              </WppButton>
              <WppButton variant="primary" onClick={() => onSave()}>
                Save
              </WppButton>
            </div>
          )}
        </div>
      </div>

      <WppModal size="s" open={isConfirmModalOpen}>
        <h3 slot="header">Are you sure you want to cancel?</h3>
        <p slot="body">You have unsaved changes. Are you sure you want to continue?</p>

        <div slot="actions" className="flex flex-row justify-end gap-4">
          <WppButton variant="secondary" onClick={() => setIsConfirmModalOpen(false)} size="s">
            Go back
          </WppButton>
          <WppButton
            size="s"
            variant="destructive"
            onClick={() => {
              setIsConfirmModalOpen(false)
              onCancel()
            }}
          >
            Confirm
          </WppButton>
        </div>
      </WppModal>
    </>
  )
}
