// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MMh6j{min-height:4rem;overflow:hidden;text-overflow:ellipsis;display:-webkit-box;-webkit-box-orient:vertical;-webkit-line-clamp:2;line-clamp:2;white-space:inherit !important}`, "",{"version":3,"sources":["webpack://./src/pages/relevant-notes/fileCmp.module.scss"],"names":[],"mappings":"AACA,OACE,eAAA,CACA,eAAA,CACA,sBAAA,CACA,mBAAA,CACA,2BAAA,CACA,oBAAA,CACA,YAAA,CACA,8BAAA","sourcesContent":["\n.headingTruncation{\n  min-height: 4rem;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  display: -webkit-box;\n  -webkit-box-orient: vertical;\n  -webkit-line-clamp: 2;\n  line-clamp: 2;\n  white-space: unset!important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headingTruncation": `MMh6j`
};
export default ___CSS_LOADER_EXPORT___;
