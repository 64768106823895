import {
  CancelDrop,
  pointerWithin,
  DndContext,
  DragOverlay,
  DropAnimation,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  Modifiers,
  UniqueIdentifier,
  useSensors,
  useSensor,
  MeasuringStrategy,
  KeyboardCoordinateGetter,
  defaultDropAnimationSideEffects,
} from '@dnd-kit/core'
import {
  AnimateLayoutChanges,
  SortableContext,
  useSortable,
  arrayMove,
  defaultAnimateLayoutChanges,
  verticalListSortingStrategy,
  SortingStrategy,
  horizontalListSortingStrategy,
} from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { createPortal } from 'react-dom'

import useTaskContext from 'hooks/useTaskContext'
import { RfiQuestion } from 'types/rfis/rfi'

import { Container, Props as ContainerProps } from './Container'
import { Item } from './DraggableItem'
import { coordinateGetter as multipleContainersCoordinateGetter } from './multipleContainersKeyboardCoordinates'

const animateLayoutChanges: AnimateLayoutChanges = args => defaultAnimateLayoutChanges({ ...args, wasDragging: true })

const defaultInitializer = (index: number) => index

export function createRange<T = number>(length: number, initializer: (index: number) => any = defaultInitializer): T[] {
  return [...new Array(length)].map((_, index) => initializer(index))
}

function DroppableContainer({
  children,
  columns = 1,
  disabled,
  id,
  items,
  style,
  ...props
}: ContainerProps & {
  disabled?: boolean
  id: string
  items: string[]
  style?: React.CSSProperties
}) {
  const { active, attributes, isDragging, listeners, over, setNodeRef, transition, transform } = useSortable({
    id,
    data: {
      type: 'container',
      children: items,
    },
    animateLayoutChanges,
  })
  const isOverContainer = over
    ? (id === over.id && active?.data.current?.type !== 'container') || items.includes(over.id as string)
    : false

  if (id.toString() === 'null' || !children) {
    return null
  }
  return (
    <Container
      ref={disabled ? undefined : setNodeRef}
      style={{
        ...style,
        transition,
        transform: CSS.Translate.toString(transform),
        opacity: isDragging ? 0.5 : undefined,
      }}
      hover={isOverContainer}
      handleProps={{
        ...attributes,
        ...listeners,
      }}
      columns={columns}
      {...props}
    >
      {children}
    </Container>
  )
}

const dropAnimation: DropAnimation = {
  sideEffects: defaultDropAnimationSideEffects({
    styles: {
      active: {
        opacity: '0.5',
      },
    },
  }),
}

type Items = {
  id: string
  name: string
  questions: RfiQuestion[]
}[]

interface Props {
  adjustScale?: boolean
  cancelDrop?: CancelDrop
  columns?: number
  containerStyle?: React.CSSProperties
  coordinateGetter?: KeyboardCoordinateGetter
  getItemStyles?(args: {
    value: UniqueIdentifier
    index: number
    overIndex: number
    isDragging: boolean
    containerId: UniqueIdentifier
    isSorting: boolean
    isDragOverlay: boolean
  }): React.CSSProperties
  wrapperStyle?(args: { index: number }): React.CSSProperties
  // itemCount?: number
  items: Items
  handle?: boolean
  renderItem?: any
  strategy?: SortingStrategy
  modifiers?: Modifiers
  minimal?: boolean
  trashable?: boolean
  scrollable?: boolean
  vertical?: boolean
  setItems: React.Dispatch<React.SetStateAction<Items>>
  savePositions?(overContainer: string, prevId: string | null, nextId: string | null, activeId: string): void
  questionsFromBe: RfiQuestion[]
  disabled: boolean
}

export const TRASH_ID = 'void'
const PLACEHOLDER_ID = 'placeholder'

export function MultipleContainers({
  adjustScale = false,
  cancelDrop,
  handle = false,
  items,
  setItems,
  coordinateGetter = multipleContainersCoordinateGetter,
  getItemStyles = () => ({}),
  wrapperStyle = () => ({}),
  minimal = false,
  modifiers,
  renderItem,
  strategy = verticalListSortingStrategy,
  vertical = true,
  scrollable,
  savePositions,
  questionsFromBe,
  disabled,
}: Props) {
  const [containers, setContainers] = useState(items.map(i => i.id))
  const [activeId, setActiveId] = useState<string | null>(null)
  const recentlyMovedToNewContainer = useRef(false)
  const { tasks } = useTaskContext()
  const processingQuestionIds = tasks.reduce((acc: string[], task) => {
    if (task.completed === false) {
      acc.push(task.resultObjectId)
    }
    return acc
  }, [])

  const disableQuestion = (id: string) =>
    processingQuestionIds.length === 2 && !processingQuestionIds.includes(id) && !elementAlreadyAnswered.includes(id)

  const [clonedItems, setClonedItems] = useState<Items | null>(null)
  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter,
    }),
  )
  const findContainer = (id: string) => {
    if (items.findIndex(i => i.id === id) >= 0) {
      return id
    }

    return items.find(i => i.questions.map(question => question.id).includes(id))?.id
  }

  const getIndex = (id: string) => {
    const containerId = findContainer(id)

    if (!containerId) {
      return -1
    }

    const index = items.findIndex(i => i.id === containerId)

    return index
  }

  const onDragCancel = () => {
    if (clonedItems) {
      // Reset items to their original state in case items have been
      // Dragged across containers
      setItems(clonedItems)
    }

    setActiveId(null)
    setClonedItems(null)
  }

  useEffect(() => {
    requestAnimationFrame(() => {
      recentlyMovedToNewContainer.current = false
    })
  }, [items])

  const elementAlreadyAnswered = useMemo(() => {
    return questionsFromBe.filter(q => q.proposedAnswer).map(q => q.id)
  }, [questionsFromBe])

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={pointerWithin}
      measuring={{
        droppable: {
          strategy: MeasuringStrategy.Always,
        },
      }}
      onDragStart={({ active }) => {
        setActiveId(active.id as string)
        setClonedItems(items)
      }}
      onDragOver={({ active, over }) => {
        const overId = over?.id as string
        const activeId = active.id as string
        if (overId == null || overId === TRASH_ID || items.findIndex(i => i.id === activeId) >= 0) {
          return
        }

        const overContainer = findContainer(overId)
        const activeContainer = findContainer(activeId)

        if (!overContainer || !activeContainer) {
          return
        }

        if (activeContainer !== overContainer) {
          setItems(items => {
            const activeItems = items.find(i => i.id === activeContainer)?.questions || []
            const overItems = items.find(i => i.id === overContainer)?.questions || []
            const overIndex = overItems?.findIndex(o => o.id === overId) ?? -1
            const activeIndex = activeItems?.findIndex(a => a.id === activeId) ?? -1

            let newIndex: number

            if (items.map(i => i.id).includes(overId)) {
              newIndex = overItems.length + 1
            } else {
              const isBelowOverItem =
                over &&
                active.rect.current.translated &&
                active.rect.current.translated.top > over.rect.top + over.rect.height

              const modifier = isBelowOverItem ? 1 : 0

              newIndex = overIndex >= 0 ? overIndex + modifier : overItems.length + 1
            }

            recentlyMovedToNewContainer.current = true

            return items.map(i => {
              if (i.id === activeContainer) {
                return {
                  ...i,
                  questions: i.questions.filter(q => q.id !== activeId),
                }
              }

              if (i.id === overContainer) {
                return {
                  ...i,
                  questions: [
                    ...i.questions.slice(0, newIndex),
                    items.find(i => i.id === activeContainer)!.questions[activeIndex],
                    ...i.questions.slice(newIndex, i.questions.length),
                  ],
                }
              }

              return i
            })
          })
        }
      }}
      onDragEnd={({ active, over }) => {
        const activeId = active.id as string
        const overId = over?.id as string
        const containerIds = items.map(i => i.id)

        // TODO: if draggable container
        if (containerIds.includes(activeId) && overId) {
          setContainers(containers => {
            const activeIndex = containers.indexOf(activeId)
            const overIndex = containers.indexOf(overId)
            return arrayMove(containers, activeIndex, overIndex)
          })
        }

        const activeContainer = findContainer(activeId)

        if (!activeContainer) {
          setActiveId(null)
          return
        }

        if (overId == null) {
          setActiveId(null)
          return
        }

        const overContainer = findContainer(overId as string)

        if (overContainer) {
          const activeIndex =
            items.find(i => i.id === activeContainer)?.questions.findIndex(i => i.id === activeId) ?? -1
          const overIndex = items.find(i => i.id === overContainer)?.questions.findIndex(i => i.id === overId) ?? -1

          if (activeIndex >= 0 && overIndex >= 0 && savePositions) {
            const tmpQuestions = [...questionsFromBe]
            const fromIndex = tmpQuestions.findIndex(q => q.id === activeId)
            const toIndex = tmpQuestions.findIndex(q => q.id === overId)
            const elementToMove = tmpQuestions.splice(fromIndex, 1)[0]
            tmpQuestions.splice(toIndex, 0, elementToMove)

            const prevId = toIndex - 1 < 0 ? null : tmpQuestions[toIndex - 1].id
            const nextId = toIndex + 1 >= tmpQuestions.length ? null : tmpQuestions[toIndex + 1].id

            savePositions(overContainer, prevId, nextId, activeId)
          }

          if (activeIndex !== overIndex) {
            setItems(items =>
              items.map(i => {
                if (i.id !== overContainer) return i
                return { ...i, questions: arrayMove(i.questions, activeIndex, overIndex) }
              }),
            )
          }
        }

        setActiveId(null)
      }}
      cancelDrop={cancelDrop}
      onDragCancel={onDragCancel}
      modifiers={modifiers}
    >
      <div className="w-full">
        <SortableContext
          disabled={disabled}
          items={[...containers, PLACEHOLDER_ID]}
          strategy={vertical ? verticalListSortingStrategy : horizontalListSortingStrategy}
        >
          {containers.map(containerId => (
            <DroppableContainer
              key={containerId}
              id={containerId}
              label={minimal ? undefined : items.find(i => i.id === containerId)?.name}
              items={items.find(i => i.id === containerId)?.questions.map(q => q.id) || []}
              scrollable={scrollable}
              // style={containerStyle}
              unstyled={minimal}
              disabled={disabled}
            >
              <SortableContext items={items.find(i => i.id === containerId)?.questions || []} strategy={strategy}>
                {items
                  .find(i => i.id === containerId)
                  ?.questions.map((value, index) => {
                    return (
                      <SortableItem
                        key={value.id}
                        disabled={disabled}
                        disableQuestion={disableQuestion(value.id)}
                        id={value.id}
                        value={`${value.questionText}`}
                        index={index}
                        handle={handle}
                        style={getItemStyles}
                        wrapperStyle={wrapperStyle}
                        renderItem={renderItem}
                        containerId={containerId}
                        getIndex={getIndex}
                        alreadyAnsered={elementAlreadyAnswered.includes(value.id)}
                      />
                    )
                  })}
              </SortableContext>
            </DroppableContainer>
          ))}
        </SortableContext>
      </div>
      {createPortal(
        <DragOverlay adjustScale={adjustScale} dropAnimation={dropAnimation}>
          {activeId
            ? containers.includes(activeId)
              ? renderContainerDragOverlay(activeId)
              : renderSortableItemDragOverlay(
                  activeId,
                  items
                    .map(i => i.questions)
                    .flat()
                    .find(q => q.id === activeId)?.questionText || activeId,
                )
            : null}
        </DragOverlay>,
        document.body,
      )}
      {/* {trashable && activeId && !containers.includes(activeId) ? <Trash id={TRASH_ID} /> : null} */}
    </DndContext>
  )

  function renderSortableItemDragOverlay(id: string, value: string) {
    return (
      <Item
        value={value}
        id={id}
        handle={handle}
        style={getItemStyles({
          containerId: findContainer(id) as UniqueIdentifier,
          overIndex: -1,
          index: getIndex(id),
          value: id,
          isSorting: true,
          isDragging: true,
          isDragOverlay: true,
        })}
        wrapperStyle={wrapperStyle({ index: 0 })}
        renderItem={renderItem}
        dragOverlay
      />
    )
  }

  function renderContainerDragOverlay(containerId: string) {
    const container = items.find(i => i.id === containerId)
    return (
      <Container
        label={container?.name || 'Group has no name'}
        style={{
          height: '100%',
        }}
        shadow
        unstyled={false}
      >
        {items
          .find(i => i.id === containerId)
          ?.questions?.map((item, index) => (
            <Item
              key={item.id}
              value={item.questionText}
              id={item.id}
              handle={handle}
              style={getItemStyles({
                containerId,
                overIndex: -1,
                index: getIndex(item.id),
                value: item.questionText,
                isDragging: false,
                isSorting: false,
                isDragOverlay: false,
              })}
              wrapperStyle={wrapperStyle({ index })}
              renderItem={renderItem}
            />
          ))}
      </Container>
    )
  }
}

interface SortableItemProps {
  containerId: UniqueIdentifier
  id: UniqueIdentifier
  index: number
  handle: boolean
  disabled?: boolean
  disableQuestion?: boolean
  style(args: any): React.CSSProperties
  getIndex(id: UniqueIdentifier): number
  renderItem(): React.ReactElement
  wrapperStyle({ index }: { index: number }): React.CSSProperties
  value: string
  alreadyAnsered: boolean
}

function SortableItem({
  disabled,
  disableQuestion,
  id,
  index,
  handle,
  renderItem,
  style,
  containerId,
  getIndex,
  wrapperStyle,
  value,
  alreadyAnsered,
}: SortableItemProps) {
  const { setNodeRef, setActivatorNodeRef, listeners, isDragging, isSorting, over, overIndex, transform, transition } =
    useSortable({
      id,
    })
  const mounted = useMountStatus()
  const mountedWhileDragging = isDragging && !mounted

  return (
    <Item
      ref={disabled ? undefined : setNodeRef}
      id={id as string}
      value={value}
      disabled={disabled}
      disableQuestion={disableQuestion}
      alreadyAnswered={alreadyAnsered}
      dragging={isDragging}
      sorting={isSorting}
      handle={handle}
      handleProps={handle ? { ref: setActivatorNodeRef } : undefined}
      index={index}
      wrapperStyle={wrapperStyle({ index })}
      style={style({
        index,
        value: id,
        isDragging,
        isSorting,
        overIndex: over ? getIndex(over.id) : overIndex,
        containerId,
      })}
      transition={transition}
      transform={transform}
      fadeIn={mountedWhileDragging}
      listeners={listeners}
      renderItem={renderItem}
    />
  )
}

function useMountStatus() {
  const [isMounted, setIsMounted] = useState(false)

  useEffect(() => {
    const timeout = setTimeout(() => setIsMounted(true), 500)

    return () => clearTimeout(timeout)
  }, [])

  return isMounted
}
