import { createUseQuery } from 'api/common/createUseQuery'
import { fetchMarkets } from 'api/fetchers/markets/fetchMarkets'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { MarketType } from 'types/markets/market'

export const useMarkets = createUseQuery({
  queryKey: ApiQueryKeys.MARKETS,
  fetcher: fetchMarkets,
  selector: res => res?.data?.data ?? ([] as MarketType[]),
})
