// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vlx8w{height:2rem;height:var(--li-height, 2rem)}.GOWzJ{font-size:14px;font-family:var(--wpp-font-family),sans-serif;font-family:var(--wpp-typography-m-body-font-family, var(--wpp-font-family)),sans-serif;line-height:22px;letter-spacing:0;letter-spacing:var(--wpp-typography-m-body-letter-spacing, 0);color:var(--wpp-text-color)}`, "",{"version":3,"sources":["webpack://./src/pages/home/ProjectPreviewItem.module.scss"],"names":[],"mappings":"AAAA,OACA,WAAA,CAAA,6BAAA,CAGA,OACE,cAAA,CACA,6CAAA,CAAA,uFAAA,CACA,gBAAA,CACA,gBAAA,CAAA,6DAAA,CACA,2BAAA","sourcesContent":[".Item {\nheight: var(--li-height, 2rem);\n}\n\n.value {\n  font-size: 14px;\n  font-family: var(--wpp-typography-m-body-font-family, var(--wpp-font-family)), sans-serif;\n  line-height: 22px;\n  letter-spacing: var(--wpp-typography-m-body-letter-spacing, 0);\n  color: var(--wpp-text-color);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Item": `vlx8w`,
	"value": `GOWzJ`
};
export default ___CSS_LOADER_EXPORT___;
