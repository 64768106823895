import { Outlet } from 'react-router-dom'

import { SidebarCmp } from 'components/SidebarCmp'

import style from './AppLayout.module.scss'

const AppLayout = () => {
  return (
    <div className={style.appLayoutContainer}>
      <SidebarCmp />
      <div className={style.appLayoutContent}>
        <Outlet />
      </div>
    </div>
  )
}

export default AppLayout
