import { createUseQuery } from 'api/common/createUseQuery'
import { fetchUseCases } from 'api/fetchers/use-cases/fetchUseCases'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { UseCase } from 'types/use-cases/useCase'

export const useUseCases = createUseQuery({
  queryKey: ApiQueryKeys.USE_CASES,
  fetcher: fetchUseCases,
  selector: res => res?.data ?? ([] as UseCase[]),
})
