export const getDevEnv = () => {
  return window.location.pathname.split('/')[1] === 'local' ? 'local' : null
}
/**
 * hide implementation details
 * behind query params
 *   */
export const featureFlag = (key: string, value: string) => {
  const url = new URL(window.location.href)
  const containsFeature = url.search.includes(`${key}=${value}`)
  return containsFeature ?? false
}
