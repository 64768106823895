import { createLazyQuery, createUseQuery } from 'api/common/createUseQuery'
import { fetchAgencies } from 'api/fetchers/agencies/fetchAgencies'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { Agency } from 'types/agencies/agencies'

export const useGetAgencies = createUseQuery({
  queryKey: ApiQueryKeys.AGENCIES,
  fetcher: fetchAgencies,
  selector: res => res?.data ?? ([] as Agency[]),
})

export const useGetAgenciesLazy = createLazyQuery({
  queryKey: ApiQueryKeys.AGENCIES,
  fetcher: fetchAgencies,
  selector: res => res?.data ?? ([] as Agency[]),
})
