import { MutationFunction, useMutation, UseMutationOptions } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { QueryFetcher, QueryFetcherResponse, QueryFetcherParams } from 'api/common/types'

export function createUseMutation<F extends QueryFetcher>({
  fetcher,
  onSettled,
  onMutate,
  onError,
}: {
  fetcher: F
  onSettled?:
    | ((
        data: QueryFetcherResponse<F> | undefined,
        error: AxiosError<unknown, any> | null,
        variables: QueryFetcherParams<F>,
        context: unknown,
      ) => unknown)
    | undefined
  onMutate?: (variables: QueryFetcherParams<F>) => Promise<unknown> | unknown
  onError?: (error: AxiosError<unknown, any>, variables: QueryFetcherParams<F>, context: unknown) => unknown
}) {
  return function useCustomMutation(
    options?: UseMutationOptions<QueryFetcherResponse<F>, AxiosError, QueryFetcherParams<F>>,
  ) {
    return useMutation({
      mutationFn: (params => fetcher(params)()) as MutationFunction<QueryFetcherResponse<F>, QueryFetcherParams<F>>,
      ...options,
      onSettled,
      onMutate,
      onError,
    })
  }
}
