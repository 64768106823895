import { WppActionButton, WppIconDownload } from '@wppopen/components-library-react'
import axios from 'axios'
import { saveAs } from 'file-saver'
import JSZip from 'jszip'

import { useToast } from 'hooks/useToast'

const DownloadZip = ({ urls, disabled }: { urls: string[]; disabled: boolean }) => {
  const toast = useToast()
  const downloadAndZipFiles = async () => {
    const zip = new JSZip()
    const pdfFolder = zip.folder('pdfs')

    const fetchFile = async (url: string) => {
      const response = await axios.get(url, {
        responseType: 'blob',
      })
      if (response.status !== 200) {
        toast.showToast({
          message: 'Something went wrong while downloading the files. Please try again.',
          type: 'error',
        })
        return
      }
      return response.data
    }

    const promises = urls.map(async (url, index) => {
      const fileData = await fetchFile(url)
      const fileName = `file${index + 1}.pdf`
      pdfFolder && pdfFolder.file(fileName, fileData)
    })

    await Promise.all(promises)

    const content = await zip.generateAsync({ type: 'blob' })
    saveAs(content, 'use-cases.zip')
  }
  // return <div>test</div>
  return (
    // <WppTooltip text="Download all files as a zip">
    <WppActionButton disabled={disabled} variant="secondary" onClick={downloadAndZipFiles}>
      <WppIconDownload slot="icon-start" />
    </WppActionButton>
    // </WppTooltip>
  )
}

export default DownloadZip
