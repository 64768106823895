import { rfiHelperFormApi } from 'api'
import { AgencyAddedResponse } from 'types/agencies/agencies'

/**
 * pass formData file and params to add an agency
 * @param params - name and description of the agency
 * @param formData - file to upload
 * */

export interface AddAgencyParams {
  params: {
    name: string
    description: string
  }
  agencyId?: string
  formData?: FormData
}

export const addAgency = (data: AddAgencyParams) => {
  const entries = Object.entries(data.params)
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
    .join('&')

  if (data?.formData) {
    return rfiHelperFormApi.post<AgencyAddedResponse>(`/agencies?${entries}`, data.formData)
  }

  return rfiHelperFormApi.post<AgencyAddedResponse>(`/agencies?${entries}`)
}
