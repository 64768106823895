import { createUseQuery } from 'api/common/createUseQuery'
import { fetchAgencyCreators } from 'api/fetchers/agencies/fetchAgencyNames'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { AgencyCreatorsResponse } from 'types/agencies/agencies'

export const useGetAgencyCreators = createUseQuery({
  queryKey: ApiQueryKeys.AGENCY_NAMES,
  fetcher: fetchAgencyCreators,
  selector: res => res?.data || ([] as AgencyCreatorsResponse),
})
