import { rfiHelperApi } from 'api'
import { UseCase } from 'types/use-cases/useCase'

export const fetchUseCases = ({ rfiId, agencyIds }: { rfiId: string; agencyIds: string[] }) =>
  rfiHelperApi.get<UseCase[]>('/use-cases', {
    params: {
      rfiId,
      agencyIds: agencyIds.join(','),
    },
    paramsSerializer: { indexes: null },
  })
