import { createApi } from 'api/common/createApi'
import config, { API_TYPES } from 'constants/app-config'

export const masterDataApi = createApi({
  baseURL: config[API_TYPES.MASTER_DATA_API],
})

export const rfiHelperApi = createApi({
  baseURL: config[API_TYPES.RFI_HELPER_API],
})

export const rfiHelperFormApi = createApi({
  baseURL: config[API_TYPES.RFI_HELPER_API],
  headers: {
    'Content-Type': 'multipart/form-data',
  },
})

export const rfiHelperDeleteApi = createApi({
  baseURL: config[API_TYPES.RFI_HELPER_API],
  headers: {
    'Content-Type': 'multipart/form-data',
  },
})

export const userDataApi = createApi({
  baseURL: config[API_TYPES.USERS_API],
})
