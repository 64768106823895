import { rfiHelperFormApi } from 'api'
import { AgencyAddedResponse } from 'types/agencies/agencies'

/**
 * pass formData file and params to add an agency
 * @param params - agencyId, name and description of the agency
 * @param formData - file to upload
 * */
export const updateAgency = (data: { agencyId?: string; params: { [key: string]: string }; formData?: FormData }) => {
  const entries = Object.entries(data.params)
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
    .join('&')

  if (data?.formData) {
    return rfiHelperFormApi.put<AgencyAddedResponse>(`/agencies/${data.agencyId}?${entries}`, data.formData)
  }

  return rfiHelperFormApi.put<AgencyAddedResponse>(`/agencies/${data.agencyId}?${entries}`)
}
