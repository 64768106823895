import { ToastState } from '@wppopen/components-library'
import { MayBeNull } from '@wppopen/core'
import { useEffect, useRef } from 'react'

export const useToast = () => {
  const toastRef = useRef<MayBeNull<HTMLWppToastContainerElement>>()

  const showToast = (config: ToastState) => {
    toastRef.current?.addToast(config)
  }

  useEffect(() => {
    toastRef.current = document.querySelector('.wpp-toast-container') as HTMLWppToastContainerElement
  }, [])

  return {
    showToast,
  }
}
