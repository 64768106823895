// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fpwuE::part(button){border-top-right-radius:0;border-bottom-right-radius:0}.RW_aS::part(button){border-top-left-radius:0;border-bottom-left-radius:0}.h2pHj::part(button){background-color:#e0ebff}`, "",{"version":3,"sources":["webpack://./src/pages/agency-dashboard/AgencyFilterGroup/agencyFilterGroup.module.scss"],"names":[],"mappings":"AAAA,qBACI,yBAAA,CACA,4BAAA,CAEJ,qBACI,wBAAA,CACA,2BAAA,CAEJ,qBACI,wBAAA","sourcesContent":[".lButton::part(button) {\n    border-top-right-radius: 0;\n    border-bottom-right-radius: 0;\n}\n.rButton::part(button) {\n    border-top-left-radius: 0;\n    border-bottom-left-radius: 0;   \n}\n.selected::part(button) {\n    background-color:#E0EBFF;\n \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"lButton": `fpwuE`,
	"rButton": `RW_aS`,
	"selected": `h2pHj`
};
export default ___CSS_LOADER_EXPORT___;
