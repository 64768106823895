import { rfiHelperApi } from 'api'
import { ProjectDetails } from 'types/rfis/rfi'

export const setQuestionPosition = ({
  prevId,
  nextId,
  rfiCategoryId,
  activeId,
}: {
  prevId: string | null
  nextId: string | null
  activeId: string
  rfiCategoryId: string
}) =>
  rfiHelperApi.put<ProjectDetails[]>(`/rfi_questions/${activeId}/set_position`, {
    nextId,
    rfiCategoryId,
    prevId,
  })
