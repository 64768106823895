import { WppTooltip, WppTypography } from '@wppopen/components-library-react'
import clsx from 'clsx'
import { Fragment, ReactNode, useEffect, useRef, useState } from 'react'

import style from './ProjectPreviewItem.module.scss'

export const ProjectPreviewItem = ({
  children,
  labelText,
  containerWidth = 0,
  value = '',
}: {
  children?: ReactNode
  labelText: string
  containerWidth: number
  value: string
}) => {
  const [showTooltip, setShowTooltip] = useState(false)
  const [labelWidth, setLabelWidth] = useState(0)
  const textRef = useRef<HTMLSpanElement>(null)

  useEffect(() => {
    if (value && textRef.current) {
      const childNodes = document.querySelector('[data-row]')?.children
      const childrenWidth =
        (childNodes &&
          Array.from(childNodes).reduce((acc, child, index) => {
            // last node is potentially truncated
            if (index !== childNodes.length - 1) acc += (child as HTMLElement).offsetWidth + 8 //
            return acc
          }, 0)) ||
        0

      const labelWidth = document.querySelector('[data-label]')?.getBoundingClientRect().width || 0
      const availableWidth = containerWidth - childrenWidth
      const textWidth = textRef?.current?.scrollWidth || document.querySelector('[data-text]')?.scrollWidth || 0

      setShowTooltip(textWidth >= availableWidth)
      setLabelWidth(labelWidth)
    }
  }, [containerWidth, value, textRef])

  const Component = showTooltip
    ? ({ children }: { children: ReactNode }) => (
        <WppTooltip className="w-full" text={value}>
          {children}
        </WppTooltip>
      )
    : Fragment
  const width = (labelWidth && `${labelWidth}px`) || '100px'

  return (
    <div className={clsx('flex justify-start gap-2', style.Item)} data-row>
      <p className="whitespace-nowrap">
        <WppTypography type="s-midi" className="text-grey">
          {labelText}{' '}
        </WppTypography>
      </p>
      {children ? children : null}
      <p className="w-full" data-label>
        <Component>
          <span
            data-text
            ref={textRef}
            style={{ maxWidth: width }}
            className={clsx('truncate inline-block', style.value)}
          >
            {value}
          </span>
        </Component>
      </p>
    </div>
  )
}
