import { createUseQuery } from 'api/common/createUseQuery'
import { fetchPitchTypes } from 'api/fetchers/pitch-types/fetchPitchTypes'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { PitchType } from 'types/pitch-types/pitchType'

export const usePitchTypes = createUseQuery({
  queryKey: ApiQueryKeys.PITCH_TYPES,
  fetcher: fetchPitchTypes,
  selector: res => res?.data ?? ([] as PitchType[]),
})
