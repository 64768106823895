import { WppBreadcrumb, WppIconChevron, WppTypography } from '@wppopen/components-library-react'
import { useNavigate } from 'react-router-dom'

import { useGetAgencies } from 'api/queries/agencies/useGetAgencies'
import { useToast } from 'hooks/useToast'

import { AgencyDashboardView } from './AgencyDashboardView'

export const AgencyDashboard = () => {
  const navigate = useNavigate()
  const toast = useToast()
  const { data = [], isLoading, isError } = useGetAgencies()

  if (isError) {
    toast.showToast({ message: 'Failed to fetch agencies', type: 'error' })
  }

  return (
    <div className="pb-16">
      <div className="flex mb-4">
        <WppIconChevron direction="left" />
        <WppBreadcrumb
          onWppChange={event => navigate(event.detail.path)}
          items={[{ label: 'Back to Project Dashboard', path: '/' }]}
        />
      </div>

      <div className="flex justify-start mb-4">
        <WppTypography type="xl-heading">Agency Management</WppTypography>
      </div>

      <AgencyDashboardView navigate={navigate} agencies={data} isFetching={isLoading && !isError} />
    </div>
  )
}
