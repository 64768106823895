import { createLazyQuery, createUseQuery } from 'api/common/createUseQuery'
import { fetchRfiQuestionId } from 'api/fetchers/rfis/fetchRfiQuestionId'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { RfiQuestion } from 'types/rfis/rfi'

export const useRfiQuestionId = createUseQuery({
  queryKey: ApiQueryKeys.RFI_QUESTION,
  fetcher: fetchRfiQuestionId,
  selector: res => res?.data ?? ({} as RfiQuestion),
})

export const getRfiQuestionById = createLazyQuery({
  queryKey: ApiQueryKeys.RFI_QUESTION,
  fetcher: fetchRfiQuestionId,
  selector: res => res?.data ?? ({} as RfiQuestion),
})
