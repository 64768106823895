import { masterDataApi } from 'api'
import { MarketType } from 'types/markets/market'

export const fetchMarkets = () =>
  masterDataApi.get<{ data: MarketType[] }>('/markets', {
    params: {
      page: 1,
      itemsPerPage: 500,
    },
  })
