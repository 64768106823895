import { createUseQuery } from 'api/common/createUseQuery'
import { fetchRegions } from 'api/fetchers/markets/fetchRegions'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { RegionType } from 'types/markets/region'

export const useRegions = createUseQuery({
  queryKey: ApiQueryKeys.REGIONS,
  fetcher: fetchRegions,
  selector: res => res?.data?.data ?? ([] as RegionType[]),
})
