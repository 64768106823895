import { masterDataApi } from 'api'
import { RegionType } from 'types/markets/region'

export const fetchRegions = () =>
  masterDataApi.get<{ data: RegionType[] }>('/regions', {
    params: {
      page: 1,
      itemsPerPage: 500,
    },
  })
