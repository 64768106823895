import { WppButton } from '@wppopen/components-library-react'

import { useDeleteAgency } from 'api/mutations/agencies/useDeleteAgency'
import { queryClient } from 'app/Root'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { useToast } from 'hooks/useToast'

export const AgencyDeleteModal = ({
  agencyId,
  name,
  handleModalClose,
}: {
  agencyId: string
  name: string
  handleModalClose: () => void
}) => {
  const toast = useToast()
  const { mutateAsync: deleteAgency } = useDeleteAgency()
  const handleDeleteConfirm = async () => {
    try {
      await deleteAgency(agencyId)
      queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.AGENCIES] }).then(() => {
        toast.showToast({
          message: 'Agency deleted',
          type: 'success',
        })
      })
      // close modal
      handleModalClose()
    } catch (e) {
      toast.showToast({
        message: 'Error deleting agency',
        type: 'error',
      })
    }
  }

  return (
    <>
      <h3 slot="header">Delete Agency</h3>
      <p slot="body">
        This will permanently delete <strong>{name}</strong> from this tool, it will also remove it from the project.
        Are you sure you want to delete <strong>{name}</strong>?
      </p>

      <div slot="actions" className="flex flex-row justify-end gap-4">
        <WppButton variant="secondary" onClick={handleModalClose}>
          Cancel
        </WppButton>
        <WppButton variant="destructive" onClick={handleDeleteConfirm}>
          Delete
        </WppButton>
      </div>
    </>
  )
}
