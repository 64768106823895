import { WppTypography, WppButton } from '@wppopen/components-library-react'

import styles from './LoadMore.module.scss'

interface ILoadMore {
  total: number
  currentCount: number
  width?: number
  onLoadMore: () => void
  disabled?: boolean
}

export const LoadMore = ({ disabled, onLoadMore, total, currentCount, width }: ILoadMore) => {
  const progress = Math.floor((currentCount / total) * 100)
  return (
    <div className="flex flex-col gap-2 justify-center items-center">
      <WppTypography className={styles.subText}>
        {currentCount} of {total} items
      </WppTypography>
      <div
        className="relative rounded-lg flex items-center justify-center h-[4px] bg-grey50"
        style={{ width: width ?? '200px' }}
      >
        <div className="absolute rounded-lg left-0 h-[4px] bg-primary" style={{ width: `${progress}%` }} />
      </div>
      <WppButton
        variant="secondary"
        onClick={onLoadMore}
        className={styles.loadMoreButton}
        disabled={currentCount === total || disabled}
      >
        Load more
      </WppButton>
    </div>
  )
}
