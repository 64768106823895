import { WppSpinner } from '@wppopen/components-library-react'
import { Outlet, useParams } from 'react-router-dom'

import { useProjectPermission } from 'hooks/useProjectPermission'
import { NoAccess } from 'pages/NoAccess'

export const ProjectRoutes = () => {
  const { projectId } = useParams()
  const { hasProjectPermission, isCheckingPermission } = useProjectPermission(projectId)

  if (isCheckingPermission) {
    return (
      <div className="flex w-full items-center justify-center h-[20dvh]">
        <WppSpinner size="m" />
      </div>
    )
  }
  return hasProjectPermission ? <Outlet /> : <NoAccess />
}
