import { rfiHelperApi } from 'api'
import { ProgressApiRes } from 'components/LoaderProgressWithDescription'

export const fetchTaskStatus = ({ taskId }: { taskId: string }) =>
  rfiHelperApi.get<ProgressApiRes>(`/tasks-status/${taskId}`)

export const fetchTaskStatuses = ({ taskIds }: { taskIds?: string[] }) => {
  const searchString = taskIds?.reduce((acc, taskId, index) => {
    return `${acc}${index === 0 ? '?' : '&'}id=${taskId}`
  }, '')
  return rfiHelperApi.get<ProgressApiRes[]>(`/tasks-status${searchString}`)
}
