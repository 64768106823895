// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HBMEg::part(item){padding-left:0}.HBMEg::part(item):hover{background-color:rgba(0,0,0,0)}.evHg5{min-height:4rem;overflow:hidden;text-overflow:ellipsis;display:-webkit-box;-webkit-box-orient:vertical;-webkit-line-clamp:2;line-clamp:2;white-space:inherit !important}`, "",{"version":3,"sources":["webpack://./src/pages/home/home.module.scss"],"names":[],"mappings":"AAAA,mBACK,cAAA,CAEG,yBACA,8BAAA,CAIR,OACI,eAAA,CACA,eAAA,CACA,sBAAA,CACA,mBAAA,CACA,2BAAA,CACA,oBAAA,CACA,YAAA,CACA,8BAAA","sourcesContent":[".listItem::part(item){\n     padding-left: 0;\n    //  width: max-content!important;\n        &:hover{\n        background-color: transparent;\n    }\n}\n\n.headingTruncation{\n    min-height: 4rem;\n    overflow: hidden;\n    text-overflow: ellipsis;\n    display: -webkit-box;\n    -webkit-box-orient: vertical;\n    -webkit-line-clamp: 2;\n    line-clamp: 2;\n    white-space: unset!important;\n}\n \n\n\n "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"listItem": `HBMEg`,
	"headingTruncation": `evHg5`
};
export default ___CSS_LOADER_EXPORT___;
