import { rfiHelperApi } from 'api'
import { ProgressApiRes } from 'components/LoaderProgressWithDescription'

export const addRfiQuestion = ({
  questionText,
  rfiId,
  rfiCategoryId,
}: {
  questionText: string
  rfiId: string | null
  rfiCategoryId: string
}) =>
  rfiHelperApi.post<ProgressApiRes>('/rfi_questions', {
    questionText,
    rfiCategoryId,
    rfiId,
  })
