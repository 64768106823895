import { WppTypography } from '@wppopen/components-library-react'
import clsx from 'clsx'

import { Agency } from 'types/agencies/agencies'

import style from './agencyDetails.module.scss'
import AgencyFileList from './AgencyFileList/AgencyFileList'

export const AgencyDetails = ({
  agency,
  onKnowledgeBaseUpdate,
  isEditMode,
}: {
  agency: Agency
  onKnowledgeBaseUpdate: () => void
  isEditMode: boolean
}) => {
  return (
    <div className={clsx('flex flex-col gap-5 pb-24', style.tab)}>
      {!isEditMode && (
        <div>
          <WppTypography type="s-body">{agency.description}</WppTypography>
        </div>
      )}

      <AgencyFileList agencyId={agency.id} onKnowledgeBaseUpdate={onKnowledgeBaseUpdate} />
    </div>
  )
}
