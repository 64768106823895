import { createUseQuery } from 'api/common/createUseQuery'
import { fetchRfi } from 'api/fetchers/rfis/fetchRfi'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { ProjectDetails } from 'types/rfis/rfi'

export const useRfi = createUseQuery({
  queryKey: ApiQueryKeys.RFI,
  fetcher: fetchRfi,
  selector: res => res?.data ?? ({} as ProjectDetails),
})
