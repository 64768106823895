import { InputChangeEventDetail } from '@wppopen/components-library'
import { WppInputCustomEvent } from '@wppopen/components-library/dist/types/components'
import {
  WppActionButton,
  WppButton,
  WppFilterButton,
  WppIconAdd,
  WppIconGear,
  WppIconReset,
  WppInput,
  WppSegmentedControl,
  WppSegmentedControlItem,
  WppSideModal,
} from '@wppopen/components-library-react'
import { AnalyticsActionType } from '@wppopen/core'
import { useState } from 'react'

import { navigation } from 'components/SidebarCmp'
import { ControlValues } from 'pages/home/ProjectDashboardView'

import { ProjectsFilterGroupProps } from './types'
import { ANALYTICS_EVENTS, trackAnalytics } from '../../utils/analytics'

export const ProjectsFilterGroup = ({
  navigate,
  agencyPermission,
  handleResetFilters,
  handleSearchChange,
  handleApplyFilters,
  handleCancelFilters,
  agencyDropdown,
  disabled = false,
  filters = null,
  controlValue,
  setFilterControlValue,
  numOfSelectedFilters,
  resetFiltersVisible,
}: ProjectsFilterGroupProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const onSearchChange = (e: WppInputCustomEvent<InputChangeEventDetail>) => handleSearchChange(e?.detail?.value || '')

  const handleOpenModal = () => {
    setIsModalOpen(true)
  }

  const [isAction, setIsAction] = useState(false)

  const handleCancel = () => {
    setIsAction(true)
    handleCloseModal()
    handleCancelFilters()
    setFilterControlValue(ControlValues.ALL)
  }

  const handleCloseModal = () => setIsModalOpen(false)
  const handleReset = () => {
    handleResetFilters()
  }
  const handleApply = () => {
    setIsAction(true)
    handleApplyFilters()
    handleCloseModal()
  }

  const handleNewProjectClick = () => {
    navigate(navigation.newProject)
    trackAnalytics({
      type: AnalyticsActionType.action,
      payload: ANALYTICS_EVENTS.NEW_PROJECT_BUTTON_CLICK_ACTION,
    })
  }

  const closeComplete = () => {
    if (!isAction) {
      handleCancelFilters()
    }
    setIsAction(false)
  }

  return (
    <>
      <div className="flex max-w-[80%]">
        <div className="flex gap-4 basis-1/2">
          <WppInput
            className="min-w-60"
            disabled={disabled}
            size="s"
            placeholder="Search"
            aria-label="Search agencies"
            id="agencySearch"
            onWppChange={onSearchChange}
          />
          {agencyDropdown && agencyDropdown}
          <WppFilterButton name="Filters" onClick={handleOpenModal} disabled={disabled} counter={numOfSelectedFilters}>
            Filters
          </WppFilterButton>
        </div>
      </div>

      <div className="flex gap-4 ml-auto">
        {agencyPermission && (
          <WppActionButton variant="secondary" onClick={() => navigate(navigation.agencyDashboard)}>
            <WppIconGear slot="icon-start" />
          </WppActionButton>
        )}
        <WppButton variant="primary" onClick={() => handleNewProjectClick()} size="s">
          <WppIconAdd slot="icon-start" />
          New Project
        </WppButton>

        <WppSideModal
          size="s"
          open={isModalOpen}
          onWppSideModalClose={handleCloseModal}
          onWppSideModalCloseComplete={closeComplete}
        >
          <>
            <h3 slot="header">Filter</h3>
            <div slot="body" className="flex flex-col gap-6">
              <>
                <WppSegmentedControl
                  value={controlValue}
                  onWppChange={e =>
                    setFilterControlValue((String(e.detail.value) as ControlValues) || ControlValues.ALL)
                  }
                >
                  <WppSegmentedControlItem value={ControlValues.ALL}>{ControlValues.ALL}</WppSegmentedControlItem>
                  <WppSegmentedControlItem value={ControlValues.MY_PROJECTS} disabled={disabled}>
                    {ControlValues.MY_PROJECTS}
                  </WppSegmentedControlItem>
                  <WppSegmentedControlItem value={ControlValues.SHARED_WITH_ME}>
                    {ControlValues.SHARED_WITH_ME}
                  </WppSegmentedControlItem>
                </WppSegmentedControl>
                {filters}
              </>
            </div>
            <div slot="actions" className="flex justify-between">
              {resetFiltersVisible && (
                <WppActionButton variant="primary" onClick={handleReset}>
                  Reset <WppIconReset slot="icon-start" />
                </WppActionButton>
              )}

              <WppButton variant="secondary" size="s" onClick={handleCancel} className="ml-auto mr-3">
                Cancel
              </WppButton>
              <WppButton variant="primary" size="s" onClick={handleApply}>
                Apply
              </WppButton>
            </div>
          </>
        </WppSideModal>
      </div>
    </>
  )
}
