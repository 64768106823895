import { useOs } from '@wppopen/react'

import { useRfi } from 'api/queries/rfis/useRfi'

export const useProjectPermission = (projectId: string | null | undefined = null) => {
  const {
    osContext: { userDetails },
  } = useOs()

  let hasProjectPermission = false

  const { data: project, isLoading: isCheckingPermission } = useRfi({
    params: { rfiId: projectId || '' },
    enabled: !!projectId,
  })

  if (projectId === null) {
    hasProjectPermission = true
    return { hasProjectPermission, isCheckingPermission: false }
  }

  hasProjectPermission = Boolean(
    (project && project?.projectMembers?.find(member => member.memberDetail.email === userDetails.email)) || false,
  )

  return { hasProjectPermission, isCheckingPermission }
}
