import { createUseQuery } from 'api/common/createUseQuery'
import { fetchRfiQuestions } from 'api/fetchers/rfis/fetchRfiQuestions'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { RfiQuestion } from 'types/rfis/rfi'

export const useRfiQuestions = createUseQuery({
  queryKey: ApiQueryKeys.RFI_QUESTIONS,
  fetcher: fetchRfiQuestions,
  selector: res => res?.data ?? ([] as RfiQuestion[]),
})
