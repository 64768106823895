import PptxGenJS from 'pptxgenjs'

/**
 * This function takes an HTML string and returns a plain text string.
 * @param htmlString - The HTML string to convert to plain text.
 * @returns The plain text representation of the HTML string.
 */
export const stripHtml = (htmlString: string): string => {
  const tempElement = document.createElement('div')
  tempElement.innerHTML = htmlString
  return tempElement.textContent || tempElement.innerText || ''
}

/**
 * This function takes an HTML string and returns an array of objects representing the child elements of the HTML string.
 * @param htmlString - The HTML string to convert to an array of objects.
 * @returns The array of objects representing the child elements of the HTML string.
 *
 */
export const htmlTextFormatter = (htmlString: string, options?: { color: string }): PptxGenJS.TableRow[] => {
  const div = document.createElement('div')
  div.innerHTML = htmlString
  const children = div.children
  const result = []
  for (const child of Array.from(children)) {
    // remove just the first line break
    const text = (child as HTMLElement).innerText

    let fontSize = 10
    let bold = false
    let bullet = false

    if (child.tagName === 'P') {
      fontSize = 10
    }
    if (child.tagName === 'H1') {
      text.replace(/\n/, '')
      fontSize = 16
      bold = true
    }
    if (child.tagName === 'H2') {
      text.replace(/\n/, '')
      fontSize = 14
      bold = true
    }
    if (child.tagName === 'H3') {
      text.replace(/\n/, '')
      fontSize = 12
      bold = true
    }
    if (child.tagName === 'H4') {
      text.replace(/\n/, '')
      fontSize = 10
      bold = true
    }
    if (child.tagName === 'UL') {
      fontSize = 10
    }
    if (child.tagName === 'OL') {
      fontSize = 10
    }
    if (child.tagName === 'LI') {
      fontSize = 10
      bullet = true
    }
    if (child.tagName === 'SPAN') {
    }

    result.push([
      {
        text,
        options: {
          color: options?.color || '000000',
          fontSize,
          bold,
          bullet,
          breakLine: false,
          tag: child.tagName,
        },
      },
    ])
  }

  // loop through result array, and where there is a bullet, if there are any line breaks, create a new bullet item for each line break
  // and position them in order, after the first bullet item and/or previous line break
  // and if the fontSize is 14 or 16, then insert an empty text item with the 'breakLine' property set to true
  const newResult = []
  for (let i = 0; i < result.length; i++) {
    const item = result[i][0]
    const text = item.text
    const options = item.options
    const lines = text.split('\n').filter(line => line !== '')
    if (text && options.bullet && lines.length > 1) {
      for (let j = 0; j < lines.length; j++) {
        if (j === 0) {
          newResult.push([{ text: lines[j], options }])
        } else {
          newResult.push([{ text: lines[j], options: { bullet: true } }])
        }
      }
    } else {
      newResult.push([{ text, options }])
    }
  }
  // replace all '\n' with 'breakLine' property set to false
  // and replace line break with an empty string
  const resultNoBreaks = newResult.map(item => {
    const text = item[0].text
    const options = item[0].options
    return [{ text: text.trim(), options: { ...options, breakLine: false } }]
  })

  return [...resultNoBreaks]
}
