import { createUseQuery } from 'api/common/createUseQuery'
import { fetchRfis } from 'api/fetchers/rfis/fetchRfis'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { ProjectDetails } from 'types/rfis/rfi'

export const useRfis = createUseQuery({
  queryKey: ApiQueryKeys.RFIS,
  fetcher: fetchRfis,
  selector: res => res?.data ?? ([] as ProjectDetails[]),
})
