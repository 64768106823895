// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `._3ZxQY{max-height:calc(100dvh - 50px - var(--wpp-os-header-height));overflow-y:auto;scrollbar-width:thin;scrollbar-color:var(--wpp-grey-color-400) rgba(0,0,0,0);padding-bottom:60px}._3ZxQY::-webkit-scrollbar{width:8px;height:8px}._3ZxQY::-webkit-scrollbar-thumb{border:2px solid rgba(0,0,0,0);border-radius:4px;box-shadow:inset 0 0 0 2px var(--wpp-grey-color-400)}._3ZxQY::-webkit-scrollbar:hover{background-color:var(--wpp-grey-color-200)}._3ZxQY::-webkit-scrollbar-thumb:hover{border:0 solid rgba(0,0,0,0);box-shadow:inset 0 0 0 4px var(--wpp-grey-color-400)}`, "",{"version":3,"sources":["webpack://./src/pages/ai-draft-response/AiDraftResponse.module.scss","webpack://./node_modules/@wppopen/components-library/dist/collection/scrollbar.scss"],"names":[],"mappings":"AAEA,QACE,4DAAA,CACA,eAAA,CCHA,oBAAA,CACA,uDAAA,CDKA,mBAAA,CCHA,2BACE,SAAA,CACA,UAAA,CAGF,iCACE,8BAAA,CACA,iBAAA,CACA,oDAAA,CAKF,iCACE,0CAAA,CAGF,uCACE,4BAAA,CACA,oDAAA","sourcesContent":["@import '@wppopen/components-library/dist/collection/scrollbar';\n\n.pageContentRight {\n  max-height: calc(100dvh - 50px - var(--wpp-os-header-height));\n  overflow-y: auto;\n  @include scrollbarThin;\n  @include scrollbarHover;\n  padding-bottom: 60px;\n}\n","@mixin scrollbarThin() {\n  scrollbar-width: thin;\n  scrollbar-color: var(--wpp-grey-color-400) transparent;\n\n  &::-webkit-scrollbar {\n    width: 8px;\n    height: 8px;\n  }\n\n  &::-webkit-scrollbar-thumb {\n    border: 2px solid transparent;\n    border-radius: 4px;\n    box-shadow: inset 0 0 0 2px var(--wpp-grey-color-400);\n  }\n}\n\n@mixin scrollbarHover() {\n  &::-webkit-scrollbar:hover {\n    background-color: var(--wpp-grey-color-200);\n  }\n\n  &::-webkit-scrollbar-thumb:hover {\n    border: 0 solid transparent;\n    box-shadow: inset 0 0 0 4px var(--wpp-grey-color-400);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageContentRight": `_3ZxQY`
};
export default ___CSS_LOADER_EXPORT___;
