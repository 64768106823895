import { rfiHelperApi } from 'api'

export const createAgencyCategory = ({
  agencyId,
  name,
  instruction,
  parentCategoryId,
}: {
  agencyId: string
  name: string
  instruction: string | null
  parentCategoryId: string | null
}) => rfiHelperApi.post('/instructions-categories', { agencyId, name, instruction, parentCategoryId })
