import { createUseQuery } from 'api/common/createUseQuery'
import { getAllAgencyCategories } from 'api/fetchers/agencyCategories/getAllAgencyCategories'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { AgencyCategory } from 'types/agency-categories/agency-categories'

export const useGetAgencyCategories = createUseQuery({
  queryKey: ApiQueryKeys.AGENCY_CATEGORIES,
  fetcher: getAllAgencyCategories,
  selector: res => res?.data ?? ([] as AgencyCategory[]),
})
